











































import { Vue, Component, Mixins } from "vue-property-decorator";
import ConnectionSettingPanel from "../molecles/ConnectionSettingPanel.vue";
import { BrowserType, BrowserUtility, DialogBase } from "ui-gallery";
import { ConnectionSetting, QualityType } from "@/models/connection/ConnectionSetting";
import qs from "qs";
import Axios from "axios";
import { RoomInfo } from "@/models/room/RoomInfo";
import { IRoomInfo } from "@/models/room/IRoomInfo";

/**
 * ルーム情報を編集する為のダイアログコンポーネントを提供します
 */
@Component({ components: { ConnectionSettingPanel } })
export default class EnterConenctionSettingDialog extends Mixins(DialogBase) {
    private isMobile = false;
    private isSettingShow = false;
    private isErrorDialogShow = false;
    private cn = "";

    private connect() {
        this.enter();
    }

    /**
     * コンポーネントが作成されたきに実行されます．
     */
    private async created() {
        await this.change(this.$route.query.connectionNumber as string ?? "");
        this.fetchRoomAsync(this.cn);
    }

    private async fetchRoomAsync(cn: string) {
        try {
            this.isErrorDialogShow = false;
            const res = await Axios.get<any>("/api/rooms?cn=" + cn);
            const data = res.data.data;
            const room = new RoomInfo(data[Object.keys(data)[0]]);
            this.context = new ConnectionSetting({
                connectionNumber: room.connectionNumber,
                roomId: room.roomId,
                mode: room.mode,
                useSfu: room.useSfu,
                roomName: room.name
            });
            this.isSettingShow = true;
        }
        catch {
            this.isErrorDialogShow = true;
        }
    }

    mounted() {
        this.isMobile = this.$route.query.mobile ? this.$route.query.mobile === "true" : BrowserUtility.hasFlag(BrowserType.Mobile);
    }

    private async change(e: string) {
        console.log(e, this.cn);
        // ハイフンを消す場合
        if (this.cn.length === 5 &&
            this.cn[4] === "-") {
            await this.$nextTick(() => {
                this.cn = e.replace(/[^0-9]/g, "").substr(0, 4);
            });
            return;
        }

        const input = e.replace(/[^0-9]/g, "").substr(0, 8);
        await this.$nextTick(() => {
            if (input.length <= 3) {
                this.cn = input;
            }
            else if (input.length > 4) {
                this.cn = `${input.substring(0, 4)}-${input.substring(4)}`;
            }
        });
    }

    private enter() {
        // NOTE: ボタンを押してフラグが書き換わった後に実行するため
        this.$nextTick(() => {
            this.$router.replace({ query: this.context });
            this.context.nickName = this.context.nickName || `ゲスト${Math.floor(Math.random() * 100)}`;
            if (this.context.mode === "meeting" || this.context.mode === "voice") {
                location.assign(`extern-video-chat${this.isMobile ? "-mobile" : ""}?${qs.stringify(this.context)}`);
            }
            else {
                location.assign(`extern-document-share?${qs.stringify(this.context)}`);
            }
        });
    }
}
